


const CompareResultModal = ({ result }) => {
    const data = result || {};
    if (Object.keys(data).includes('error')) {
        return (
            <div className="modal fade" id="compareModal" tabIndex="-1" role="dialog" aria-labelledby="compareModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="compareModalLabel">Compare Result</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <pre>
                                <code>
                                    {data['error']}
                                </code>
                            </pre>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    let agg = data['aggregates'];
    return (
        <div className="modal fade" id="compareModal" tabIndex="-1" role="dialog" aria-labelledby="compareModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="compareModalLabel">Compare Result</h5>
                        {/* add button to copy to clipboard agg*/}
                        <div className="btn-group">
                            <button className="btn btn-primary" onClick={() => navigator.clipboard.writeText(JSON.stringify(agg, null, 2))}>Copy</button>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>
                    <div className="modal-body">
                        <pre>
                            <code>
                                {JSON.stringify(agg, null, 2)}
                            </code>
                        </pre>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CompareResultModal;