import { ApplicationInsights } from "@microsoft/applicationinsights-web";

let appInsights = null;

export function initializeAppInsights() {
  if (process.env.NODE_ENV === 'development') {
    return null;
  }

  if (!appInsights) {
    appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: process.env.REACT_APP_APP_INSIGHTS_KEY,
        maxBatchInterval: 1,
      },
    });
    appInsights.loadAppInsights();
  }
  return appInsights;
}

function getAppInsights() {
  if (!appInsights) {
    console.error(
      "Application Insights is not initialized. Call initializeAppInsights first."
    );
  }
  return appInsights;
}

export function startTrackPage(name) {
  const appInsights = getAppInsights();
  if (appInsights) {
    appInsights.startTrackPage(name);
  }
}

export function stopTrackPage(name, href, properties) {
  const appInsights = getAppInsights();
  if (appInsights) {
    appInsights.stopTrackPage(name, href, properties);
  }
}

export function trackEvent(name, properties) {
  const appInsights = getAppInsights();
  if (appInsights) {
    appInsights.trackEvent({ name, properties });
  }
}
