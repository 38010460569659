import SnapshotsDetails from "./SnapshotsDetails"
import { reactSelectOptions } from "../../utils/app-utils"
import Select from 'react-select'
import { useGetAdminsListQuery } from "../api/oktaSlice"
import { useUpdateTrackerMutation } from "../api/collectedVideosSlice"
import useShowToastr from "../../custom-hooks/useShowToastr";
import EditorComments from "./EditorComments"
import { useSelector } from "react-redux"
import { selectIsAdmin } from "../auth/authSlice"
import PathConstants from "../../routes/pathConstants";

const VideoDetails = ({ video, refetchVideos, codersOptions, setSelectedVideo, isSimple }) => {
    const isAdmin = useSelector(selectIsAdmin);
    const { data: adminsList } = useGetAdminsListQuery();
    const [updateTracker, { isSuccess: updatedSuccessfully, isError: failedToUpdate }] = useUpdateTrackerMutation();

    useShowToastr({ isSuccess: updatedSuccessfully, isError: failedToUpdate, title: "Update Tracker", successMsg: "Tracker updated successfully", errorMsg: "Failed to update tracker" })

    const adminsOptions = adminsList ? [
        { label: 'None', value: '' },
        ...adminsList.map(admin => ({ label: admin.name, value: admin.name }))
    ] : null;

    const statusOptions = [
        { label: 'To Do', value: 'To Do' },
        { label: 'In Progress', value: 'In Progress' },
        { label: 'In Review', value: 'In Review' },
        { label: 'Done', value: 'Done' },
    ]

    const typeOfCodingOptions = [
        { label: 'Trimming', value: 'Trimming' },
        { label: 'Serve & Return', value: 'Serve & Return' },
        { label: 'GS', value: 'GS' },
        { label: 'Full', value: 'Full' },
    ]

    const updateTrackerHandler = async () => {
        await updateTracker({ videoProcessId: video.id, editorTracker: video.editorTracker });
        if (refetchVideos !== undefined && typeof refetchVideos === 'function')
            refetchVideos();
    }

    const setEditorTracker = (tracker) => {
        setSelectedVideo({ ...video, editorTracker: tracker });
    }

    const openVideosLinkingPageHandler = () => {
        const editorUrl = `${process.env.REACT_APP_PUBLIC_URL}/${PathConstants.VIDEOSLINKING}/${video.source}?videoProcessId=${video.id}`;
        window.open(editorUrl, '_blank', 'noopener,noreferrer');
    }

    const openBrowserURL = () => {
        window.open(video.browserURL, '_blank', 'noopener,noreferrer');
    }

    return (
        <div style={{ height: "70vh" }} className="d-flex flex-column pr-2 pl-6">
            {!isSimple && (
                <div className="row align-items-center">
                    <h4 className="text-center my-4 ml-auto">{video.title}</h4>
                    {isAdmin && <div className="ml-auto mr-3">
                        <button className="btn btn-sm btn-primary mr-2" onClick={openVideosLinkingPageHandler}>Videos Linking</button>
                        <button className="btn btn-sm btn-primary" onClick={openBrowserURL}>browserURL</button>
                    </div>}
                </div>
            )}

            <div style={{ maxWidth: "50%" }} className="w-100 ml-0">
                {isAdmin &&
                    <>
                        <div className="form-inline my-2 ml-0 flex-nowrap">
                            <label className="fs-1 mr-4 col-2 justify-content-start pl-0" htmlFor="reporter">Reporter</label>
                            {adminsOptions &&
                                <Select
                                    className="w-50 dark-theme"
                                    styles={reactSelectOptions}
                                    options={adminsOptions}
                                    placeholder="None"
                                    value={adminsOptions.find(admin => admin.value === video.editorTracker.reporter) ?? { label: 'None', value: '' }}
                                    onChange={(selectedOption) => setEditorTracker({ ...video.editorTracker, reporter: selectedOption.value })}
                                />
                            }
                        </div>
                        <div className="form-inline my-2 ml-0 flex-nowrap">
                            <label className="fs-1 mr-4 col-2 justify-content-start pl-0" htmlFor="assignee">Assignee</label>
                            {codersOptions &&
                                <Select
                                    className="w-50 dark-theme"
                                    styles={reactSelectOptions}
                                    options={codersOptions}
                                    placeholder="None"
                                    value={codersOptions.find(coder => coder.value === video.editorTracker.assignee) ?? { label: 'None', value: '' }}
                                    onChange={(selectedOption) => setEditorTracker({ ...video.editorTracker, assignee: selectedOption.value })}
                                />
                            }
                        </div>
                        <div className="form-inline my-2 ml-0 flex-nowrap">
                            <label className="fs-1 mr-4 col-2 justify-content-start pl-0" htmlFor="status">Coding</label>
                            <Select
                                className="w-50 dark-theme"
                                styles={reactSelectOptions}
                                options={typeOfCodingOptions}
                                placeholder="None"
                                value={typeOfCodingOptions.find(type => type.value === video.editorTracker.typeOfCoding)}
                                onChange={(selectedOption) => setEditorTracker({ ...video.editorTracker, typeOfCoding: selectedOption.value })}
                            />
                        </div>
                    </>
                }
                {
                    !isAdmin && <div className="form-inline my-2 ml-0 flex-nowrap">
                        <label className="fs-1 mr-4 col-2 justify-content-start pl-0" htmlFor="coding">Coding</label>
                        <input type="text" className="form-control w-50" value={video.editorTracker.typeOfCoding} readOnly />
                    </div>
                }

                <div className="form-inline my-2 ml-0 flex-nowrap">
                    <label className="fs-1 mr-4 col-2 justify-content-start pl-0" htmlFor="status">Status</label>
                    <Select
                        className="w-50 dark-theme"
                        styles={reactSelectOptions}
                        options={statusOptions}
                        placeholder="None"
                        value={statusOptions.find(status => status.value === video.editorTracker.status)}
                        onChange={(selectedOption) => setEditorTracker({ ...video.editorTracker, status: selectedOption.value })}
                    />

                </div>
                <button className="btn btn-primary mb-4 mt-2 mr-2" onClick={updateTrackerHandler}>Update</button>
                <button className="btn btn-dark mb-4 mt-2" onClick={() => $("#editorCommentsModal").modal("show")}>View Comments ({video.editorTracker.comments.length})</button>
                <div style={{ maxHeight: "100%", overflowX: "hidden", width: "60%" }} className='scrollbar perfect-scrollbar'>
                    <EditorComments onApplyClicked={updateTrackerHandler} videoProcessId={video.id} comments={video.editorTracker.comments} setComments={(comments) => setEditorTracker({ ...video.editorTracker, comments: comments })} />
                </div>
            </div>
            <div style={{ flexGrow: 1, overflowX: "hidden" }} className="scrollbar perfect-scrollbar">
                <SnapshotsDetails key={video.id} videoProcessId={video.id} snapshots={video.snapshots} setSelectedVideoSnapshots={(snapshots) => setSelectedVideo({ ...video, snapshots: snapshots })} />
            </div>
        </div>
    );
}

export default VideoDetails;