import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectMatch,
  selectActivePoint,
  editActivePoint,
  resetPoint,
  setActivePointWithStep,
  updatePoint,
  selectGlobalEditScore,
  updateMatch,
  setLastScoresSequence,
  selectCurrentPlayerTime
} from "../store/editorSlice.js";
import { useUpdateSnapshotPointMutation } from "../../api/snapshotsSlice.js";
import Switch from "../../../components/Switch.js";
import ButtonGroup from "../../../components/ButtonGroup.js";
import TimeInput from "../../../components/TimeInput.js";
import NumberInput from "../../../components/NumberInput.js";
import ScoreInput from "../../../components/ScoreInput.js";
import { serviceCourtOptions, rallyEndingShotTypeOptions } from "../Utils/options.js";
import NavigationControls from "./NavigationControls.js";
import VideoPlayerTimeButton from "./VideoPlayerTimeButton.js";
import useShowToastr from "../../../custom-hooks/useShowToastr.js";
import { secondsToTimeSpan } from "../../../utils/app-utils.js";
import { updateScoreSequence } from "../updateScoreSequence.js";
import { updatePointScore, rallyLengthChanged, rallyEndingChanged } from "../Utils/pointUtils.js";
import EditServeAndReturn from "./EditServeAndReturn.js";
import EditGS from "./EditGS.js";
import { trackEvent } from "../../../applicationInsights/appInsights";
import AppTrackingEvents from "../../../applicationInsights/eventConstants";
import { useOktaAuth } from "@okta/okta-react"; 
import { EDITOR_TRACKING_EVENTS } from "../Utils/constants.js";

export default function EditPoint({ videoProcessId, snapshot }) {
  const match = useSelector(selectMatch);
  const isGlobalEditScore = useSelector(selectGlobalEditScore);
  const activePoint = useSelector(selectActivePoint);
  const dispatch = useDispatch();
  const { authState, oktaAuth } = useOktaAuth();
  const currentPlayerTime = useSelector(selectCurrentPlayerTime);

  const isCVSnapshot = match.createdBy == 'CV';

  const [updateSnapshotPoint, { isSuccess, isError }] = useUpdateSnapshotPointMutation();
  useShowToastr({ isSuccess: isSuccess, isError: isError, title: "Update Point", successMsg: "Point Updated Successfully!", errorMsg: "Failed to update point!" });
  const adjustCounter = match.points[0].pointNumber === 0 ? 1 : 0;

  const playerOptions = [
    { value: match?.player1, label: match?.player1 },
    { value: match?.player2, label: match?.player2 },
  ];

  const optionChangedHandler = (field, value) => {
    const updatedPoint = { ...activePoint, [field]: value };
    trackEditPointEvent(field, activePoint[field], value);
    dispatch(editActivePoint(updatedPoint));
  }

  const scoreChangedHandler = (field, updatedScore) => {
    let updatedPoint = { ...activePoint };

    if (updatedScore.split(' ').length < 3) {
      updatedPoint.score = updatedScore
      dispatch(editActivePoint(updatedPoint));
      return;
    }
    trackEditPointEvent(field, activePoint[field], updatedScore);
    updatedPoint = updatePointScore(updatedPoint, updatedScore, match);
    dispatch(editActivePoint(updatedPoint));
  }

  const serverChangedHandler = (field, value) => {
    const updatedPoint = { ...activePoint, server: value, returner: value === match.player1 ? match.player2 : match.player1 };
    trackEditPointEvent(field, activePoint[field], value);
    const udpatedPoint = updatePointScore(updatedPoint, activePoint.score, match);
    dispatch(editActivePoint(udpatedPoint));
  };

  const rallyLengthChangedHandler = (field, value) => {
    let updatedPoint = { ...activePoint, rallyLength: value };
    trackEditPointEvent(field, activePoint[field], value);
    updatedPoint = rallyLengthChanged(updatedPoint);
    dispatch(editActivePoint(updatedPoint));
  }

  const rallyEndingChangedHandler = (field, value) => {
    let updatedPoint = { ...activePoint, rallyEndingShotTypeId: value };
    trackEditPointEvent(field, activePoint[field], value);
    updatedPoint = rallyEndingChanged(updatedPoint);
    dispatch(editActivePoint(updatedPoint));
  }

  const winnerChangedHandler = (field, value) => {
    const updatedMatch = { ...match };
    updatedMatch.points = updatedMatch.points.map((p) => p.id == activePoint.id ? { ...activePoint, winner: value } : p);

    trackEditPointEvent(field, activePoint[field], value);

    if (isGlobalEditScore) {
      const startScore = match.points[0].score;
      const config = { "advantage": match.typeOfScoring == 1, "grouping": match.matchFormat };
      const winnerSequence = updatedMatch.points.map((p) => p.winner == match.player1 ? "p1" : "p2");

      const updatedSequence = updateScoreSequence(config, startScore, winnerSequence);
      for (let i = 0; i < updatedMatch.points.length; i++) {
        updatedMatch.points[i] = updatePointScore(updatedMatch.points[i], updatedSequence[i], updatedMatch);
      }
      dispatch(setLastScoresSequence(match.points.map((p) => p.score)));
    }

    dispatch(updateMatch(updatedMatch));
  }

  const commitPointHandler = async () => {
    // if current snapshot by CV then don't update the point
    if (isCVSnapshot) {
      toastr.error("Can't update point for CV created snapshot!, Create snapshot first.", "Update Point");
      return;
    }
    var udpatedPoint = { ...activePoint };
    udpatedPoint.startTime = secondsToTimeSpan(udpatedPoint.startTime);
    udpatedPoint.endTime = secondsToTimeSpan(udpatedPoint.endTime);

    await updateSnapshotPoint({ videoProcessId: videoProcessId, snapshot: snapshot, point: udpatedPoint });
    dispatch(updatePoint(activePoint));
  }

  const trackEditPointEvent = (field, oldValue, newValue) => {
    if (oldValue === newValue) {
      return;
    }
    trackEvent(AppTrackingEvents.EDITING_POINT_ACTION, {
      videoProcessId: videoProcessId,
      user: authState.idToken.claims.preferred_username,
      event: field,
      initialValue: oldValue,
      newValue: newValue,
      pointId: activePoint.id,
      timestamp: new Date().toISOString(),
      pointScore: activePoint.score,
      currentTime: currentPlayerTime
    });
  }

  return (
    <div className="card mt-1 min-vh-75 max-vh-75">
      <div className="card-header">
        <div className="d-flex align-items-center">
          <div className="d-flex justify-content-start ml-2 mr-3">
            <h5>Edit Point {activePoint.pointNumber + adjustCounter}/{match.points.length}</h5>
          </div>
          <div className=" d-flex justify-content-end">
            <NavigationControls />
          </div>
        </div>
      </div>

      <div className="card-body scrollbar perfect-scrollbar pt-0">

        <div className="d-flex align-items-center mb-1">
          <span className="mr-1">CV Needs Review</span>
          <Switch field="needsReview" value={activePoint.needsReview} onChanged={optionChangedHandler} />
        </div>

        <div className="d-flex align-items-center mb-1">
          <span className="mr-1">Player Error</span>
          <Switch field="hasPlayerError" value={activePoint.hasPlayerError} onChanged={optionChangedHandler} />
        </div>

        <div className="d-flex align-items-center mb-1">
          <span className="mr-1">Start Time</span>
          <div style={{ width: "50%" }} className="mr-3">
            <TimeInput field="startTime" initialTime={activePoint.startTime} onTimeChanged={optionChangedHandler} />
          </div>
          <VideoPlayerTimeButton field="startTime" onClick={optionChangedHandler} />
        </div>

        <div className="d-flex align-items-center mb-1">
          <span className="mr-1">Score</span>
          <ScoreInput field="score" value={activePoint.score} onScoreChanged={scoreChangedHandler} />
        </div>

        <div className="d-flex align-items-center mb-1">
          <div>
            <span className="mr-1">Pressure</span>
            <Switch field="isPressurePoint" value={activePoint.isPressurePoint} onChanged={optionChangedHandler} />
          </div>

          <div>
            <span className="mr-1">Break</span>
            <Switch field="isBreakPoint" value={activePoint.isBreakPoint} onChanged={optionChangedHandler} />
          </div>
        </div>

        <hr className="w-75"></hr>

        <div className="d-flex">
          <div className="d-flex flex-column align-items-left mr-2">
            <span className="mr-1">Server</span>
            <ButtonGroup field="server" value={activePoint.server} options={playerOptions} onOptionChanged={serverChangedHandler} />
          </div>

          <div className="d-flex flex-column align-items-left">
            <span className="mr-1">Court</span>
            <ButtonGroup field="serviceCourt" value={activePoint.serviceCourt} options={serviceCourtOptions} onOptionChanged={optionChangedHandler} />
          </div>

        </div>

        <hr className="w-75"></hr>

        {match.typeOfCoding != "Trimming" && <div>
          <ul className="nav nav-tabs">
            <li className="nav-item">
              <a className="nav-link active" data-toggle="tab" href="#edit-serve-return" role="tab" aria-controls="serve-and-return" aria-selected="true">S&R</a>
            </li>
            {
              match.typeOfCoding == "GS" &&
              <li className="nav-item">
                <a className="nav-link" data-toggle="tab" href="#edit-gs" role="tab" aria-controls="gs" aria-selected="false">GS</a>
              </li>
            }
          </ul>
          <div className="tab-content pt-3 pr-3 pb-3" >
            <div id="edit-serve-return" className="tab-pane fade show active">
              <EditServeAndReturn />
            </div>
            {
              match.typeOfCoding == "GS" &&
              <div id="edit-gs" className="tab-pane fade show">
                <EditGS />
              </div>
            }
          </div>
          <hr className="w-75"></hr>
        </div>
        }

        {
          match.typeOfCoding == "GS" &&
          <div className="d-flex align-items-center mb-1">
            <span className="mr-1">Rally Ending</span>
            <ButtonGroup field="rallyEndingShotTypeId" value={activePoint.rallyEndingShotTypeId} options={rallyEndingShotTypeOptions} onOptionChanged={rallyEndingChangedHandler} />
          </div>}

        <div className="d-flex align-items-center mb-1">
          <span className="mr-1">Rally Length</span>
          <NumberInput field="rallyLength" value={activePoint.rallyLength} onNumberChanged={rallyLengthChangedHandler} />
        </div>
        <div className="d-flex align-items-center mb-1">
          <span className="mr-1">Winner</span>
          <ButtonGroup field="winner" value={activePoint.winner} options={playerOptions} onOptionChanged={winnerChangedHandler} />
        </div>

        <div className="d-flex align-items-center mb-3">
          <span className="mr-1">End Time</span>
          <div style={{ width: "50%" }} className="mr-3">
            <TimeInput field="endTime" initialTime={activePoint.endTime} onTimeChanged={optionChangedHandler} />
          </div>
          <VideoPlayerTimeButton field="endTime" onClick={optionChangedHandler} />
        </div>

        <div className="row align-items-center justify-content-center">
          <button className="btn btn-primary mr-2" onClick={commitPointHandler}>Commit Point <span className="ml-1 fas fa-database"></span></button>
          <button className="btn btn-warning" onClick={() => {
            if (isCVSnapshot) {
              toastr.error("Can't reset point for CV created snapshot!, Create snapshot first.", "Reset Point");
              return;
            }
            trackEditPointEvent(EDITOR_TRACKING_EVENTS.RESET_POINT, null, null);
            dispatch(resetPoint());
          }}>Reset Point</button>
        </div>

        <div className="d-flex justify-content-end mt-2">
          <button
            className="btn btn-outline-primary"
            type="button"
            data-toggle="tooltip"
            data-placement="top"
            title="next"
            onClick={() => {
              dispatch(setActivePointWithStep({ step: 1 }));
            }}
          >
            Next Point
            <span className="mx-2 fas fa-step-forward"></span>
          </button>
        </div>
      </div>
    </div>
  );
}
