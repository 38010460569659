import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { useNavigate } from "react-router-dom";
import config from "./config";
import Routes from "./routes/routes";
import { Security } from "@okta/okta-react";
import store from "./store";
import { Provider } from "react-redux";
import { initializeAppInsights } from "./applicationInsights/appInsights";

function App() {
  const oktaAuth = new OktaAuth(config.oidc);

  const navigate = useNavigate();
  const restoreOriginalUri = (_oktaAuth, originalUri) => {
    navigate(toRelativeUrl(originalUri || "/", window.location.origin));
  };

  initializeAppInsights();

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <Provider store={store}>
        <Routes />
      </Provider>
    </Security>
  );
}

export default App;
