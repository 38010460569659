import { useGetLocalEventInstancesQuery, useGetAllLocalPlayersQuery } from "../../../features/api/completeMatchesSlice";
import { useCreateStandardReportMutation } from "../../../features/api/reportExecutionSlice";
import { useGetStdReportMatchesCountMutation } from "../../../features/api/reportsSlice";
import { useEffect, useState } from "react";
import Select from "react-select";
import Spinner from "../../../UI/Spinner";
import TrackingSheet from "./TrackingSheet";
import useDropboxPath from "../../../custom-hooks/useDropboxPath";
import useShowToastr from "../../../custom-hooks/useShowToastr";
import { stdInitialInputs, yearsOptions, categoryOptions, opponentTypeOptions, backhandTypeOptions, gameResultSelectionOptions, matchResultSelectionOptions, setResultSelectionOptions, gameServeSelectionOptions, useHEDataOptions } from "../reportsOptions";

const NewStandardReport = ({ reportExecutionId, editingReport, target, isUserReport, clientSettings }) => {
    const [tournamentOptions, setTournamentOptions] = useState([]);
    const [playerOptions, setPlayerOptions] = useState([]);
    const [trackingSheet, setTrackingSheet] = useState(null);

    const [formInputs, setFormInputs] = useState(stdInitialInputs);

    const invalidInputs = formInputs.selectedTargetPlayers.length === 0 || (formInputs.opponent === 3 && formInputs.selectedOpponentPlayers.length === 0)

    const { data: tournaments } = useGetLocalEventInstancesQuery();
    const { data: allPlayers } = useGetAllLocalPlayersQuery();

    const [getStdReportMatchesCount, { isLoading: loadingReportMatchesCount, isError: reportMatchesCountError }] = useGetStdReportMatchesCountMutation();

    const [createStandardRecord, { isLoading: loadingReportCreation, isSuccess: createdSuccessfully, isError: creationError }] = useCreateStandardReportMutation();

    const { userSettings, setUserSettings } = useDropboxPath({ isUserReport, container: "std-dropbox-container", setFormInputs });

    useShowToastr({ isSuccess: createdSuccessfully, isError: creationError, successMsg: "Created Successfully!", errorMsg: "Failed to create!", title: "Create New Report", modalId: "AddNewStandardReportModal" });

    useShowToastr({ isError: reportMatchesCountError, errorMsg: "Failed to get matches count!", title: "Matches Count" });

    useEffect(() => {
        if (clientSettings) {
            setFormInputs({ ...formInputs, targetSpeedUnit: clientSettings.defaultSpeedUnit });
        }
    }, [clientSettings])

    useEffect(() => {
        if (editingReport) { setFormInputs(editingReport.reportInputs); }
        else {
            const newFormInputs = { ...stdInitialInputs, dropboxOutputDir: userSettings?.defaultDropboxPath };
            const targetPlayer = allPlayers?.find(p => p.localPlayerId === target.localPlayerId);

            if (targetPlayer) {
                newFormInputs.category = targetPlayer.category;
                newFormInputs.selectedTargetPlayers = [targetPlayer.localName];
            }

            setFormInputs(newFormInputs);
        }
    }, [editingReport])

    useEffect(() => {
        setTrackingSheet(null);
    }, [formInputs])

    useEffect(() => {
        if (tournaments) {
            setTournamentOptions(tournaments
                .filter(t => t.category === categoryOptions.find(c => c.value === formInputs.category).label)
                .map((tournament) => ({
                    label: `${tournament.name} - ${tournament.category}`,
                    value: tournament.name
                })));
        }
    }, [tournaments, formInputs.category]);


    useEffect(() => {
        if (allPlayers) {
            const targetPlayer = allPlayers.find(p => p.localPlayerId === target.localPlayerId);
            var category = formInputs.category;

            if (targetPlayer) {
                category = targetPlayer.category;
                setFormInputs({ ...formInputs, selectedTargetPlayers: [targetPlayer.localName], category: category });
            } else {
                setFormInputs({ ...formInputs, selectedTargetPlayers: [] });
            }

            setPlayerOptions(allPlayers
                .filter(p => p.category === categoryOptions.find(c => c.value === category).value)
                .map((player) => ({
                    label: player.name,
                    value: player.localName
                })));
        }
    }, [allPlayers, formInputs.category, target.localPlayerId]);

    const opponentChangedHandler = (e) => {
        const opponentType = parseInt(e.target.value);
        const newFormInputs = { ...formInputs, opponent: opponentType, selectedOpponentPlayers: [] };
        if (opponentType === 3)
            newFormInputs.backhandType = backhandTypeOptions[0].value;
        setFormInputs(newFormInputs);
    };

    const handleCreateReport = async () => {
        const payload = {
            ...formInputs,
            blobOutputDir: `report-automation/${reportExecutionId}/standard-report`
        };

        await createStandardRecord({ reportInputsModel: payload, reportExecutionId: reportExecutionId, reportId: editingReport?.reportId });
    }

    const matchesCountHandler = async () => {
        var response = await getStdReportMatchesCount(formInputs);
        if (response)
            setTrackingSheet(response.data);
    }

    return (
        <div
            className="modal fade"
            id="AddNewStandardReportModal"
            role="dialog"
            aria-labelledby="AddNewStandardReportModalLabel"
            aria-hidden="true"
        >
            <div style={{ width: "fit-content", minWidth: "60%" }} className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h3 className="modal-title" id="AddNewStandardReportModalLabel">
                            {editingReport ? "Edit Standard Report" : "Create New Standard Report"}
                        </h3>
                    </div>
                    <div style={{ height: "75vh" }} className="modal-body scrollbar perfect-scrollbar">
                        <div className="container mb-2">
                            <div className="form-group">
                                <div className="row ml-0">
                                    <div className="col-xl-2 col-md-2 mr-1">
                                        <label htmlFor="category">Category</label>
                                        <select
                                            className="form-control mb-3"
                                            id="category"
                                            value={formInputs.category}
                                            onChange={(e) => setFormInputs({ ...formInputs, category: parseInt(e.target.value), selectedTargetPlayers: [], selectedOpponentPlayers: [], selectedTournaments: [] })}>
                                            {categoryOptions.map((option) => (
                                                <option key={option.value} value={option.value}>
                                                    {option.label}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col-xl-3 col-md-3 mb-2">
                                        <label >Target Player</label>
                                        {playerOptions.length === 0 ? <Spinner /> : <Select
                                            className="dark-theme"
                                            value={formInputs.selectedTargetPlayers.length > 0 ? playerOptions.find(p => p.value === formInputs.selectedTargetPlayers[0]) : null}
                                            options={playerOptions}
                                            onChange={(selectedOption) => setFormInputs({ ...formInputs, selectedTargetPlayers: [selectedOption.value] })} />}
                                    </div>
                                    <div className="col-xl-3 col-md-3 mb-2">
                                        <label htmlFor="targetSpeedUnit">Target Speed Unit</label>
                                        <select className="form-control" id="targetSpeedUnit"
                                            value={formInputs.targetSpeedUnit}
                                            onChange={(e) => setFormInputs({ ...formInputs, targetSpeedUnit: e.target.value })}>
                                            <option value="MPH">MPH</option>
                                            <option value="KMH">KMH</option>
                                        </select>
                                    </div>
                                    <div className="col-xl-3 col-md-3 mb-2">
                                        <label htmlFor="matchResult">Match Result</label>
                                        <select
                                            className="form-control mb-3"
                                            id="matchResultSelection"
                                            value={formInputs.selectedMatchesResult}
                                            onChange={(e) => setFormInputs({ ...formInputs, selectedMatchesResult: parseInt(e.target.value) })}>
                                            {matchResultSelectionOptions.map((option) => (
                                                <option key={option.value} value={option.value}>
                                                    {option.label}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="row ml-0">
                                    <div className="col-xl-3 col-md-3 mr-1">
                                        <label htmlFor="opponent">Opponent</label>
                                        <select
                                            className="form-control mb-3"
                                            id="opponent"
                                            value={formInputs.opponent}
                                            onChange={opponentChangedHandler}>
                                            {opponentTypeOptions.map((option) => (
                                                <option key={option.value} value={option.value}>
                                                    {option.label}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    {formInputs.opponent === 3 && <div className="col-xl-3 col-md-3 mb-2">
                                        <label >Opponent Player</label>
                                        {playerOptions.length === 0 ? <Spinner /> : <Select
                                            isMulti
                                            className="dark-theme"
                                            value={playerOptions.filter(p => formInputs.selectedOpponentPlayers.includes(p.value))}
                                            options={playerOptions}
                                            onChange={(selectedOptions) => setFormInputs({ ...formInputs, selectedOpponentPlayers: selectedOptions.map((option) => option.value) })} />}
                                    </div>}
                                    {formInputs.opponent != 3 && <div className="col-xl-3 col-md-3 mr-1">
                                        <label htmlFor="backhandType">Backhand Type</label>
                                        <select
                                            className="form-control mb-3"
                                            id="backhandType"
                                            value={formInputs.backhandType}
                                            onChange={(e) => setFormInputs({ ...formInputs, backhandType: parseInt(e.target.value) })}>
                                            {backhandTypeOptions.map((option) => (
                                                <option key={option.value} value={option.value}>
                                                    {option.label}
                                                </option>
                                            ))}
                                        </select>
                                    </div>}
                                    <div className="col-xl-3 col-md-3 mt-3">
                                        <input
                                            type="checkbox"
                                            id="withRets"
                                            checked={formInputs.withRets}
                                            onChange={(e) => setFormInputs({ ...formInputs, withRets: e.target.checked })} />
                                        <label className="ml-2 mb-0" htmlFor="withRets">Retirements</label>
                                    </div>
                                </div>
                                <div className="row ml-0">
                                    <div className="col-xl-6 col-md-6">
                                        <label htmlFor="surface">Surface</label>
                                        <div className="row ml-0">
                                            <input
                                                type="checkbox"
                                                id="isHardSelected"
                                                checked={formInputs.isHardSelected}
                                                onChange={(e) => setFormInputs({ ...formInputs, isHardSelected: e.target.checked })} />
                                            <label className="ml-2 mr-4 mb-0" htmlFor="isClaySelected">Hard</label>
                                            <input
                                                type="checkbox"
                                                id="isClaySelected"
                                                checked={formInputs.isClaySelected}
                                                onChange={(e) => setFormInputs({ ...formInputs, isClaySelected: e.target.checked })}
                                            />
                                            <label className="ml-2 mr-4 mb-0" htmlFor="isClaySelected">Clay</label>
                                            <input
                                                type="checkbox"
                                                id="isGrassSelected"
                                                checked={formInputs.isGrassSelected}
                                                onChange={(e) => setFormInputs({ ...formInputs, isGrassSelected: e.target.checked })} />
                                            <label className="ml-2 mr-4 mb-0" htmlFor="isGrassSelected">Grass</label>
                                            <input
                                                type="checkbox"
                                                id="isCarpetSelected"
                                                checked={formInputs.isCarpetSelected}
                                                onChange={(e) => setFormInputs({ ...formInputs, isCarpetSelected: e.target.checked })} />
                                            <label className="ml-2 mr-4 mb-0" htmlFor="isCarpetSelected">Carpet</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row ml-0 mt-2">
                                    <div className="col-xl-2 col-md-2">
                                        <label htmlFor="fromYear">From</label>
                                        <select
                                            className="form-control mb-3"
                                            id="fromYear"
                                            value={formInputs.fromYear}
                                            onChange={(e) => setFormInputs({ ...formInputs, fromYear: parseInt(e.target.value) })}
                                        >
                                            {yearsOptions.map((option) => (
                                                <option key={option.value} value={option.value}>
                                                    {option.label}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col-xl-2 col-md-2">
                                        <label htmlFor="toYear">To</label>
                                        <select
                                            className="form-control mb-3"
                                            id="toYear"
                                            value={formInputs.toYear}
                                            onChange={(e) => setFormInputs({ ...formInputs, toYear: parseInt(e.target.value) })}>
                                            {yearsOptions.map((option) => (
                                                <option key={option.value} value={option.value}>
                                                    {option.label}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div style={{ width: "70%" }} className="col-xl-6 col-md-6">
                                        <label htmlFor="tournaments">Tournaments</label>
                                        {tournamentOptions.length === 0 ? <Spinner /> : <Select
                                            className="dark-theme"
                                            closeMenuOnSelect={false}
                                            isMulti
                                            options={tournamentOptions}
                                            value={formInputs.selectedTournaments.length > 0 ? tournamentOptions.filter(t => formInputs.selectedTournaments.includes(t.value)) : []}
                                            onChange={(selectedOptions) => setFormInputs({ ...formInputs, selectedTournaments: selectedOptions.map((option) => option.value) })} />}
                                    </div>
                                </div>
                                <div className="row ml-0">
                                    <div className="col-xl-3 col-md-3 mt-2 mr-4">
                                        <label htmlFor="setResultSelection">Set Result</label>
                                        <select
                                            className="form-control mb-3"
                                            id="setResultSelection"
                                            value={formInputs.selectedSetsResult}
                                            onChange={(e) => setFormInputs({ ...formInputs, selectedSetsResult: parseInt(e.target.value) })}>
                                            {setResultSelectionOptions.map((option) => (
                                                <option key={option.value} value={option.value}>
                                                    {option.label}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col-xl-3 col-md-3 mt-2 mr-4">
                                        <label htmlFor="gameResultSelection">Game Result</label>
                                        <select
                                            className="form-control mb-3"
                                            id="gameResultSelection"
                                            value={formInputs.selectedGamesResult}
                                            onChange={(e) => setFormInputs({ ...formInputs, selectedGamesResult: parseInt(e.target.value) })}>
                                            {gameResultSelectionOptions.map((option) => (
                                                <option key={option.value} value={option.value}>
                                                    {option.label}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col-xl-3 col-md-3 mt-3 mr-5">
                                        <label htmlFor="gameServeSelection">Game Serve</label>
                                        <select
                                            className="form-control mb-3"
                                            id="gameServeSelection"
                                            value={formInputs.selectedServeGames}
                                            onChange={(e) => setFormInputs({ ...formInputs, selectedServeGames: parseInt(e.target.value) })}>
                                            {gameServeSelectionOptions.map((option) => (
                                                <option key={option.value} value={option.value}>
                                                    {option.label}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="row align-items-center mt-3">
                                        <input
                                            type="checkbox"
                                            id="includeTieBreakGames"
                                            checked={formInputs.includeTieBreakGames}
                                            onChange={(e) => setFormInputs({ ...formInputs, includeTieBreakGames: e.target.checked })} />
                                        <label className="ml-2 mb-0" htmlFor="includeTieBreakGames">Include Tie-Break Games</label>
                                    </div>
                                </div>
                                <div className="row ml-0">
                                    <div className="col-xl-3 col-md-3">
                                        <label htmlFor="useHEData">Use HE Data</label>
                                        <select
                                            className="form-control"
                                            id="useHEData"
                                            value={formInputs.useHEData}
                                            onChange={(e) => setFormInputs({ ...formInputs, useHEData: parseInt(e.target.value) })}>
                                            {useHEDataOptions.map((option) => (
                                                <option key={option.value} value={option.value}>
                                                    {option.label}
                                                </option>
                                            ))}
                                        </select>
                                    </div>

                                    <div className="col-xl-3 col-md-3">
                                        <label htmlFor="pdfReportType">PDF Report Type</label>
                                        <select
                                            className="form-control"
                                            id="pdfReportType"
                                            value={formInputs.pdfReportType}
                                            onChange={(e) => setFormInputs({ ...formInputs, pdfReportType: parseInt(e.target.value) })}>
                                            <option value="0">Short</option>
                                            <option value="1">Complete</option>
                                            <option value="2">Complete+</option>
                                            <option value="3">Belgian Federation</option>
                                        </select>
                                    </div>
                                    <div className="col-xl-3 col-md-3 mt-4">
                                        <input
                                            type="checkbox"
                                            id="UseMissedCustomTemplate"
                                            checked={formInputs.useMissedCustomTemplate}
                                            onChange={(e) => setFormInputs({ ...formInputs, useMissedCustomTemplate: e.target.checked })} />
                                        <label className="ml-2 mr-4 mb-0" htmlFor="UseMissedCustomTemplate">Use Custom Template</label>
                                    </div>
                                </div>
                                {
                                    isUserReport &&
                                    <div className="mt-3">
                                        <label className="fs-0 mb-0 mr-2">Upload to dropbox </label>
                                        <div className="d-inline custom-control custom-switch" onClick={() => setUserSettings((prev) => ({ ...prev, uploadStdReportByDefault: !prev.uploadStdReportByDefault }))}>
                                            <input className="custom-control-input" type="checkbox" readOnly checked={userSettings.uploadStdReportByDefault}></input>
                                            <label className="custom-control-label"></label>
                                        </div>
                                    </div>
                                }
                                {isUserReport && userSettings.uploadStdReportByDefault && <div style={{ width: "70%" }} className="d-flex row flex-nowrap mt-3 align-items-center ml-0">
                                    <div id="std-dropbox-container" />
                                    <input style={{ width: "70%" }} readOnly value={formInputs.dropboxOutputDir} className="form-control ml-2 p-0 pl-2" />
                                </div>}
                            </div>
                            <div className="row ml-0">
                                <button style={{ width: "20%" }} className="btn btn-primary btn-md mt-2 mb-0 ml-auto" type="button" disabled={loadingReportMatchesCount || invalidInputs} onClick={matchesCountHandler}> {loadingReportMatchesCount ? "Loading" : "Matches Count"}</button>
                            </div>
                            {trackingSheet && <TrackingSheet trackingSheet={trackingSheet} />}
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button className="btn btn-secondary btn-md" type="button" data-dismiss="modal">
                            Cancel
                        </button>

                        <button className="btn btn-warning btn-md" type="button"
                            onClick={handleCreateReport}
                            disabled={loadingReportCreation || invalidInputs}>
                            {!editingReport ? (loadingReportCreation ? "Creating..." : "Create Report") : (loadingReportCreation ? "Editing..." : "Edit Report")}
                        </button>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default NewStandardReport;