import { useEffect } from "react";
import { startTrackPage, stopTrackPage } from "./appInsights";

export function usePageVisibility(getPageProperties) {
  useEffect(() => {
    const handleVisibilityChange = () => {
      const pageProperties = getPageProperties();
      if (document.hidden) {
        if (pageProperties) {
          stopTrackPage(
            pageProperties.eventName,
            window.location.href,
            pageProperties
          );
        }
      } else {
        startTrackPage(pageProperties.eventName);
      }
    };

    const handleUnload = () => {
      const pageProperties = getPageProperties();
      stopTrackPage(
        pageProperties.eventName,
        window.location.href,
        pageProperties.eventName
      );
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);
    document.addEventListener("beforeunload", handleUnload);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      document.removeEventListener("beforeunload", handleUnload);
    };
  }, [getPageProperties]);
}
