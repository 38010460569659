import { createSlice } from '@reduxjs/toolkit';
import authRoles from './authRoles';
import { current } from 'immer';

const initialState = {
    user: null,
    roles: ['isBasic'],
    isAdmin: false,
    isCoder: false,
    isCV: false,
    isVideoClient: false,
    isRecordingTeam: false,
};
const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setUser(state, action) {
            state.user = action.payload;
            if(action.payload === null) {
                state.roles = ['isBasic'];
            }
            else {
                newrelic.setUserId(action.payload.preferred_username);
                for (const [key, value] of Object.entries(authRoles)) {
                    for (const role of state.user.roles) {
                        if (value.includes(role)) {
                            state.roles.push(key);
                            state[key] = true;
                        }
                    }
                }
            }
        },
    },
});
export const { setUser } = authSlice.actions;
export const selectUser = (state) => state.auth.user;
export const selectRoles = (state) => state.auth.roles;
export const selectIsAdmin = (state) => state.auth.isAdmin;
export const selectIsCoder = (state) => state.auth.isCoder;
export const selectIsCV = (state) => state.auth.isCV;
export const selectIsVideoClient = (state) => state.auth.isVideoClient;
export const selectIsRecordingTeam = (state) => state.auth.isRecordingTeam;
export default authSlice.reducer;