import Spinner from "../../UI/Spinner";
import NotAuthorizedPage from "../../pages/notAuthorizedPage";
import { useSelector } from 'react-redux';
import { selectUser, selectIsAdmin } from '../auth/authSlice';
import { Doughnut, Bar, Line } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, PointElement, LineElement } from "chart.js";
import { useEffect, useState } from "react";
import { useGetVideosScrapedQuery, useGetVideosStatusQuery, useGetVideosCvVersionsQuery, useGetVideosCodingStatusQuery, useGetCodersCodingStatusQuery, useGetVideoAppSummaryQuery } from "../api/imgaSlice";

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, PointElement, LineElement);

const IMGADashboardPage = () => {
    const user = useSelector(selectUser);
    const isAdmin = useSelector(selectIsAdmin);

    const [cvVersionsData, setcvVersionsData] = useState(null);
    const [cvVersionsSum, setcvVersionsSum] = useState(0);

    const [codingStatusData, setCodingStatusData] = useState(null);
    const [codingStatusSum, setCodingStatusSum] = useState(0);

    const [codersStatusData, setcodersStatusData] = useState(null);

    const [sAndRTotal, setSAndRTotal] = useState(0);
    const [sAndRData, setSAndRData] = useState(null);
    const [sAndRAggregationData, setSAndRAggregationData] = useState(null);

    const [gsTotal, setGSTotal] = useState(0);
    const [gsData, setGSData] = useState(null);
    const [gsAggregationData, setGSAggregationData] = useState(null);

    const { data: videosScrapedData, isLoading: isVideosScrapedDataLoading } = useGetVideosScrapedQuery();
    const { data: videosStatusData, isLoading: isVideosStatusDataLoading } = useGetVideosStatusQuery();
    const { data: videosCvVersionsData, isLoading: isVideosCvVersionsDataLoading } = useGetVideosCvVersionsQuery();
    const { data: videosCodingStatusData, isLoading: isVideosCodingStatusDataLoading } = useGetVideosCodingStatusQuery();
    const { data: codersCodingStatusData, isLoading: isCodersCodingStatusDataLoading } = useGetCodersCodingStatusQuery();
    const { data: videoAppSummaryData, isLoading: isVideoAppSummaryDataLoading } = useGetVideoAppSummaryQuery();

    useEffect(() => {
        if (!isVideosScrapedDataLoading && videosScrapedData) {
        }
    }, [isVideosScrapedDataLoading, videosScrapedData]);

    useEffect(() => {
        if (!isVideosStatusDataLoading && videosStatusData) { }
    }, [isVideosStatusDataLoading, videosStatusData]);

    useEffect(() => {
        if (!isVideosCvVersionsDataLoading && videosCvVersionsData) {
            const dataset = {
                labels: Object.keys(videosCvVersionsData),
                datasets: [
                    {
                        label: 'Videos',
                        data: Object.values(videosCvVersionsData),
                        backgroundColor: [
                            'rgba(255, 99, 132, 0.6)',   // Red
                            'rgba(54, 162, 235, 0.6)',   // Blue
                            'rgba(255, 206, 86, 0.6)',   // Yellow
                            'rgba(75, 192, 192, 0.6)',   // Teal
                            'rgba(153, 102, 255, 0.6)',   // Purple,
                            'rgba(255, 159, 64, 0.6)',  // Orange
                        ],
                        borderColor: [
                            'rgba(255, 99, 132, 1)',     // Red
                            'rgba(54, 162, 235, 1)',     // Blue
                            'rgba(255, 206, 86, 1)',     // Yellow
                            'rgba(75, 192, 192, 1)',     // Teal
                            'rgba(153, 102, 255, 1)',     // Purple,
                            'rgba(255, 159, 64, 1)',     // Orange
                        ],
                        borderWidth: 1,
                    },
                ],
            };
            setcvVersionsData(dataset);
            const cvVersionsSum = Object.values(videosCvVersionsData).reduce((a, b) => a + b, 0);
            setcvVersionsSum(cvVersionsSum);
        }
    }, [isVideosCvVersionsDataLoading, videosCvVersionsData]);

    useEffect(() => {
        if (!isVideosCodingStatusDataLoading && videosCodingStatusData) {
            const codingStatusData = {
                labels: Object.keys(videosCodingStatusData),
                datasets: [
                    {
                        label: 'Videos',
                        data: Object.values(videosCodingStatusData),
                        backgroundColor: [
                            'rgba(255, 99, 132, 0.6)',   // Red
                            'rgba(255, 206, 86, 0.6)',   // Yellow
                            'rgba(75, 192, 192, 0.6)',   // Teal
                            'rgba(54, 162, 235, 0.6)',   // Blue
                        ],
                        borderColor: [
                            'rgba(255, 99, 132, 1)',     // Red
                            'rgba(255, 206, 86, 1)',     // Yellow
                            'rgba(75, 192, 192, 1)',     // Teal
                            'rgba(54, 162, 235, 0.6)',   // Blue
                        ],
                        borderWidth: 1,
                    },
                ],
            };

            setCodingStatusData(codingStatusData);

            const codingStatusSum = Object.values(videosCodingStatusData).reduce((a, b) => a + b, 0);
            setCodingStatusSum(codingStatusSum);
        }
    }, [isVideosCodingStatusDataLoading, videosCodingStatusData]);

    useEffect(() => {
        if (!isCodersCodingStatusDataLoading && codersCodingStatusData) {
            //order data.coderStatus by number of videos descending
            const codersStatus = Object.entries(codersCodingStatusData);
            codersStatus.sort((a, b) => {
                const aSum = Object.values(a[1]).reduce((a, b) => a + b, 0);
                const bSum = Object.values(b[1]).reduce((a, b) => a + b, 0);
                return bSum - aSum;
            });
            let codersCodingStatusDataSorted = Object.fromEntries(codersStatus);

            let codersStatusData = {
                labels: Object.keys(codersCodingStatusDataSorted), // Assignee names
                datasets: [
                    {
                        label: 'To Do',
                        backgroundColor: 'rgba(255, 99, 132, 0.6)',
                        data: Object.values(codersCodingStatusDataSorted).map((assignee) => assignee['to Do']),
                    },
                    {
                        label: 'In Progress',
                        backgroundColor: 'rgba(54, 162, 235, 0.6)',
                        data: Object.values(codersCodingStatusDataSorted).map((assignee) => assignee['in Progress']),
                    },
                    {
                        label: 'In Review',
                        backgroundColor: 'rgba(255, 206, 86, 0.6)',
                        data: Object.values(codersCodingStatusDataSorted).map((assignee) => assignee['in Review']),
                    },
                    {
                        label: 'Done',
                        backgroundColor: 'rgba(75, 192, 192, 0.6)',
                        data: Object.values(codersCodingStatusDataSorted).map((assignee) => assignee['done']),
                    },
                ],
            };

            setcodersStatusData(codersStatusData);
        }
    }, [isCodersCodingStatusDataLoading, codersCodingStatusData]);

    useEffect(() => {
        if (!isVideoAppSummaryDataLoading && videoAppSummaryData) {
            const sAndRdataLocal = {
                labels: Object.keys(videoAppSummaryData.sAndR),
                datasets: [
                    {
                        label: 'S&R',
                        data: Object.values(videoAppSummaryData.sAndR),
                        borderColor: 'rgba(75, 192, 192, 1)',
                        backgroundColor: 'rgba(75, 192, 192, 0.2)',
                        borderWidth: 1,
                    },
                ],
            };

            const sAndRAggregationdata = {
                labels: Object.keys(videoAppSummaryData.sAndRAggregation),
                datasets: [
                    {
                        label: 'S&R',
                        data: Object.values(videoAppSummaryData.sAndRAggregation),
                        borderColor: 'rgba(75, 192, 192, 1)',
                        backgroundColor: 'rgba(75, 192, 192, 0.2)',
                        borderWidth: 1,
                    },
                ],
            };

            const gsdataLocal = { 
                labels: Object.keys(videoAppSummaryData.gs),
                datasets: [
                    {
                        label: 'GS',
                        data: Object.values(videoAppSummaryData.gs),
                        borderColor: 'rgba(75, 192, 192, 1)',
                        backgroundColor: 'rgba(75, 192, 192, 0.2)',
                        borderWidth: 1,
                    },
                ],
            };

            const gsAggregationdata = {
                labels: Object.keys(videoAppSummaryData.gsAggregation),
                datasets: [
                    {
                        label: 'GS',
                        data: Object.values(videoAppSummaryData.gsAggregation),
                        borderColor: 'rgba(75, 192, 192, 1)',
                        backgroundColor: 'rgba(75, 192, 192, 0.2)',
                        borderWidth: 1,
                    },
                ],
            };

            setGSTotal(videoAppSummaryData.gSTotal);
            setGSData(gsdataLocal);
            setGSAggregationData(gsAggregationdata);

            setSAndRTotal(videoAppSummaryData.sAndRTotal);
            setSAndRData(sAndRdataLocal);
            setSAndRAggregationData(sAndRAggregationdata);
        }
    }, [isVideoAppSummaryDataLoading, videoAppSummaryData]);

    const doughnutOptions = {
        responsive: true,
        plugins: {
            legend: {
                position: 'bottom',
                labels: {
                    color: 'white', // Set legend font color to white
                },
            },
            tooltip: {
                enabled: true,
            },
        },
    };

    const stackedbaroptions = {
        plugins: {
            legend: {
                position: 'bottom',
            },
        },
        responsive: true,
        scales: {
            x: {
                stacked: true,
            },
            y: {
                stacked: true,
                beginAtZero: true,
            },
        },
    };

    const lineOptions = {
        plugins: {
            legend: {
                position: 'bottom',
            },
        },
        scales: {
            x: {
                title: { display: true, text: 'Date' },
            },
            y: {
                beginAtZero: true,
                title: { display: true, text: 'Count' },
            },
        },
    };


    if (!user) return <Spinner />;
    else if (!isAdmin) return <NotAuthorizedPage />;
    return (
        <div className="content">
            <h1>IMGA Dashboard</h1>
            <div className="card-deck">
                <div className="card mb-3 overflow-hidden" style={{ minWidth: "12rem", maxWidth: "fit-content" }}>
                    <div className="bg-holder bg-card">
                    </div>
                    <div className="card-body position-relative">
                        <h6>Scraped</h6>
                        {isVideosScrapedDataLoading && <Spinner />}
                        {videosScrapedData &&
                            <div className="display-4 fs-4 mb-2 font-weight-normal text-sans-serif text-info">{videosScrapedData.scraped}</div>
                        }
                    </div>

                </div>
                <div className="card mb-3 overflow-hidden" style={{ minWidth: "12rem", maxWidth: "fit-content" }}>
                    <div className="bg-holder bg-card">
                    </div>
                    <div className="card-body position-relative">
                        <h6>Ready to be processed</h6>
                        {isVideosStatusDataLoading && <Spinner />}
                        {videosStatusData &&
                            <div className="display-4 fs-4 mb-2 font-weight-normal text-sans-serif text-info">{videosStatusData.readyToBeProcessed}</div>
                        }
                    </div>
                </div>
                <div className="card mb-3 overflow-hidden" style={{ minWidth: "12rem", maxWidth: "fit-content" }}>
                    <div className="bg-holder bg-card">
                    </div>
                    <div className="card-body position-relative">
                        <h6>CV Processed</h6>
                        {isVideosStatusDataLoading && <Spinner />}
                        {videosStatusData &&
                            <div className="display-4 fs-4 mb-2 font-weight-normal text-sans-serif text-success">{videosStatusData.cvProcessed}</div>
                        }
                    </div>
                </div>
                <div className="card mb-3 overflow-hidden" style={{ minWidth: "12rem", maxWidth: "fit-content" }}>
                    <div className="bg-holder bg-card">
                    </div>
                    <div className="card-body position-relative">
                        <h6>CV In Progress</h6>
                        {isVideosStatusDataLoading && <Spinner />}
                        {videosStatusData &&
                            <div className="display-4 fs-4 mb-2 font-weight-normal text-sans-serif text-info">{videosStatusData.cvInProgress}</div>
                        }
                    </div>
                </div>
                <div className="card mb-3 overflow-hidden" style={{ minWidth: "12rem", maxWidth: "fit-content" }}>
                    <div className="bg-holder bg-card">
                    </div>
                    <div className="card-body position-relative">
                        <h6>CV Failed</h6>
                        {isVideosStatusDataLoading && <Spinner />}
                        {videosStatusData &&
                            <div className="display-4 fs-4 mb-2 font-weight-normal text-sans-serif text-danger">{videosStatusData.cvFailed}</div>
                        }
                    </div>
                </div>
            </div>
            <div className="card-deck">
                <div className="card mb-3" style={{ maxWidth: "12rem", maxWidth: "fit-content" }}>
                    <div className="card-body">
                        <h6>CV Versions <span className="badge badge-soft-warning rounded-capsule ml-2">{cvVersionsSum}</span></h6>
                        {isVideosCvVersionsDataLoading && <Spinner />}
                        {cvVersionsData &&
                            <Doughnut id="versions" data={cvVersionsData} options={doughnutOptions} />
                        }
                    </div>
                </div>

                <div className="card mb-3" style={{ maxWidth: "12rem", maxWidth: "fit-content" }}>
                    <div className="card-body">
                        <h6>Coding Status <span className="badge badge-soft-warning rounded-capsule ml-2">{codingStatusSum}</span></h6>
                        {isVideosCodingStatusDataLoading && <Spinner />}
                        {codingStatusData &&
                            <Doughnut id="coding" data={codingStatusData} options={doughnutOptions} />
                        }
                    </div>
                </div>
                <div className="card mb-3" style={{ maxWidth: "30rem" }}>
                    <div className="card-body">
                        <h6>Coders Status</h6>
                        {isCodersCodingStatusDataLoading && <Spinner />}
                        {codersStatusData &&
                            <Bar id="coders" data={codersStatusData} options={stackedbaroptions} />
                        }
                    </div>
                </div>
            </div>

            <div className="card-deck">
                <div className="card mb-3 overflow-hidden" style={{ minWidth: "12rem", maxWidth: "fit-content" }}>
                    <div className="bg-holder bg-card">
                    </div>
                    <div className="card-body position-relative">
                        <h6>S&R On Video App</h6>
                        {isVideoAppSummaryDataLoading && <Spinner />}
                        {videoAppSummaryData &&
                            <div className="display-4 fs-4 mb-2 font-weight-normal text-sans-serif text-info">{sAndRTotal}</div>
                        }
                    </div>
                </div>
                <div className="card mb-3" style={{ maxWidth: "40rem" }}>
                    <div className="card-body">
                        <h6>S&R per day</h6>
                        {isVideoAppSummaryDataLoading && <Spinner />}
                        {videoAppSummaryData && sAndRData != null &&
                            <Bar data={sAndRData} options={stackedbaroptions} />
                        }
                    </div>
                </div>
                <div className="card mb-3" style={{ maxWidth: "40rem" }}>
                    <div className="card-body">
                        <h6>S&R aggregation</h6>
                        {isVideoAppSummaryDataLoading && <Spinner />}
                        {videoAppSummaryData && sAndRAggregationData != null &&
                            <Line data={sAndRAggregationData} options={lineOptions} />
                        }
                    </div>
                </div>
            </div>



            {/* <div className="card-deck">
                <div className="card mb-3 overflow-hidden" style={{ minWidth: "12rem", maxWidth: "fit-content" }}>
                    <div className="bg-holder bg-card">
                    </div>
                    <div className="card-body position-relative">
                        <h6>GS On Video App</h6>
                        {isVideoAppSummaryDataLoading && <Spinner />}
                        {videoAppSummaryData &&
                            <div className="display-4 fs-4 mb-2 font-weight-normal text-sans-serif text-warning">{gsTotal}</div>
                        }
                    </div>
                </div>
                <div className="card mb-3" style={{ maxWidth: "40rem" }}>
                    <div className="card-body">
                        <h6>GS per day</h6>
                        {isVideoAppSummaryDataLoading && <Spinner />}
                        {videoAppSummaryData && sAndRData != null &&
                            <Bar data={gsData} options={stackedbaroptions} />
                        }
                    </div>
                </div>
                <div className="card mb-3" style={{ maxWidth: "40rem" }}>
                    <div className="card-body">
                        <h6>GS aggregation</h6>
                        {isVideoAppSummaryDataLoading && <Spinner />}
                        {videoAppSummaryData && sAndRAggregationData != null &&
                            <Line data={gsAggregationData} options={lineOptions} />
                        }
                    </div>
                </div>
            </div> */}
        </div>
    );
}

export default IMGADashboardPage;