import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import getAuthToken from "../auth/authManager";

export const editorApiSlie = createApi({
    reducerPath: "editorapi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_URL}/editor`,
        mode: "cors",
        prepareHeaders: (headers) => {
            const token = getAuthToken();
            if (token) {
                headers.set("authorization", `Bearer ${token}`);
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        postCompareRequest: builder.mutation({
            query: (body) => ({
                url: `/snapshots/compare`,
                method: "POST",
                body,
            }),
            invalidatesTags: []
        }),
    }),
});

export const {
    usePostCompareRequestMutation
} = editorApiSlie;
